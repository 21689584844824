import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Headroom from 'react-headroom';
import logoLight from '@assets/cmm-logo-light.svg';
import logoDark from '@assets/cmm-logo-dark.svg';
import menuIcon from '@assets//icons/burger-menu.svg';
import closeIcon from '@assets/close.svg';
import BackArrowIcon from '@assets/Arrow_icon.svg';
import HomeIconSVG from '@assets/icons/home.svg';
import ServiceIconSVG from '@assets/icons/services.svg';
import IndustryIconSVG from '@assets/icons/industries.svg';
import TechnologyIconSVG from '@assets/icons/technology.svg';
import ProcessIconSVG from '@assets/icons/process.svg';

export const HeaderWrapper = styled(Headroom)`
  width: 100%;
  .headroom {
    justify-content: center;
  }
`;

export const HeadRoomInnerWrapper = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  display: flex;
  font-weight: 700;
  align-items: center;
  img {
    width: 240px;
    margin: 0;
  }
`;

export const NavCTA = styled(Link)`
  background: ${(props) => props.theme.navbar.primary0};
  color: ${(props) => props.theme.navbar.white0};
  border-radius: 25px;
  padding: 5px 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.75em;
  text-transform: uppercase;
  margin-left: 2rem;
  letter-spacing: 1px;
`;

export const NavLink = styled(Link)`
  color: ${(props) => props.theme.navbar.black0};
  margin-left: 2rem;
  position: relative;
  transition: all ${(props) => props.theme.transitions.default.duration} cubic-bezier(0.25, 0, 0.35, 0.5);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.75em;
  padding: 1rem 0;

  &:hover {
    color: ${(props) => props.theme.navbar.primary0};
    &:before,
    &:after {
      width: 100%;
    }
    &:after {
      width: 100%;
    }
  }
  &:before {
    content: ' ';
    background: ${(props) => props.theme.navbar.primary0};
    height: 2px;
    display: flex;
    width: 0;
    overflow: hidden;
    transition: width ${(props) => props.theme.transitions.default.duration} cubic-bezier(0.25, 0, 0.35, 0.5);
    position: absolute;
    bottom: 1rem;
  }
  &:after {
    color: ${(props) => props.theme.navbar.primary0};
    content: ' ';
    display: flex;
    position: absolute;
    width: 0;
    top: 1rem;
    white-space: nowrap;
    overflow: hidden;
    transition: width ${(props) => props.theme.transitions.default.duration} cubic-bezier(0.25, 0, 0.35, 0.5);
  }
  text-transform: capitalize;
  &.active {
    color: ${(props) => props.theme.navbar.primary0};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0;
  }
`;

export const NavLinkList = styled(NavLink)`
  margin-left: 0;
  margin-bottom: 5px;
  &.active {
    color: ${(props) => props.theme.navbar.white0};
  }
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  font-family: ${(props) => props.theme.fontFamily.body};
  font-weight: 500;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const SideBar = styled.div`
  position: fixed;
  width: 300px;
  height: 100vh;
  top: 0;
  opacity: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: ${(props) => props.theme.navbar.primary0};
  padding: 20px;
  right: -100%;
  transition: ${(props) => props.theme.transitions.default.transition};
  &.open {
    opacity: 1;
    transition: ${(props) => props.theme.transitions.default.transition};
    right: 0%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const MenuIcon = styled(menuIcon)`
  width: 32px;
  height: 32px;
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const LogoLight = styled(logoLight)`
  width: 200px;
`;

export const LogoDark = styled(logoDark)`
  width: 200px;
`;

export const CloseIcon = styled(closeIcon)`
  position: absolute;
  width: 18px;
  height: 18px !important;
  right: 20px;
`;

export const Chev = styled.span`
  cursor: pointer;
  width: 8px;
  height: 8px;
  position: relative;
  top: -2px;
  margin-left: 5px;
  display: inline-flex;
  border-style: solid;
  border-color: ${(props) => props.theme.navbar.black0};
  border-width: 2px 2px 0 0;
  transform: ${(p) => (p.show ? 'rotate(135deg)' : 'rotate(45deg)')};
  transition: transform 250ms ease;
`;

export const DropdownWrapper = styled.div``;

export const Dropdown = styled.div`
  display: ${(p) => (p.show ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  width: 100%;
  background: #12ebae;
  height: 100vh;
  top: 0px;
  z-index: 3;
  margin-left: -20px;
  padding: 20px;
`;

export const DropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.black.base};
  font-size: 14px;
  margin-left: 0px;
  width: 100%;
  flex-basis: 50%;
  padding: 5px 0;

  &.active {
    font-weight: bold;
  }
  &:hover {
    color: ${(props) => props.theme.colors.primary.base};
  }
  &:before {
    content: none;
  }
`;

export const SubMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SubMenuListWrapper = styled.div`
  display: ${(p) => (p.show ? 'flex' : 'none')};
  position: absolute;
  flex-direction: column;
  background-color: ${(props) => props.theme.navbar.background2};
  padding: 50px;
  left: 50%;
  top: 100%;
  z-index: 3;
  transform: translateX(-50%);
  border-radius: 8px;
`;

export const SubMenuBox = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const SubMenuInfoBox = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
  margin-right: 40px;
`;

export const SubMenuLinkBox = styled.div`
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 2;
`;

export const SubMenuTitle = styled.div`
  font-size: 28px;
  line-height: 120%;
  margin-bottom: 15px;
`;

export const SubMenuDescription = styled.div`
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 15px;
`;

export const SubMenuPageLink = styled(Link)`
  color: ${(p) => p.theme.colors.primary.base};
  font-size: 16px;
  line-height: 150%;
`;

export const MobileSubMenuBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MobileMenuTitle = styled.div`
  font-size: 20px;
  line-height: 150%;
  color: #000;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const BackArrow = styled(BackArrowIcon)`
  width: 18px;
  height: 18px !important;
  margin-right: 15px;
`;

/* For NavBarV2 */
export const NavBarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: ${(props) => props.theme.colors.white.base};
  color: ${(props) => props.theme.colors.black.base};
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80px;
`;

export const NavBarLink = styled(Link)`
  color: ${(props) => props.theme.colors.black.base};
  margin-left: 2rem;
  position: relative;
  transition: all ${(props) => props.theme.transitions.default.duration} cubic-bezier(0.25, 0, 0.35, 0.5);
  font-weight: 500;
  font-size: 16px;
  line-height: 60px;
  padding: 10px 0;

  &:hover {
    color: ${(props) => props.theme.navbar.primary0};
    &:before,
    &:after {
      width: 100%;
    }
    &:after {
      width: 100%;
    }
  }
  &:before {
    content: ' ';
    background: ${(props) => props.theme.navbar.primary0};
    height: 2px;
    display: flex;
    width: 0;
    overflow: hidden;
    transition: width ${(props) => props.theme.transitions.default.duration} cubic-bezier(0.25, 0, 0.35, 0.5);
    position: absolute;
    bottom: 1rem;
  }
  &:after {
    color: ${(props) => props.theme.navbar.primary0};
    content: ' ';
    display: flex;
    position: absolute;
    width: 0;
    top: 1rem;
    white-space: nowrap;
    overflow: hidden;
    transition: width ${(props) => props.theme.transitions.default.duration} cubic-bezier(0.25, 0, 0.35, 0.5);
  }
  text-transform: capitalize;
  &.active {
    color: ${(props) => props.theme.navbar.primary0};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding: 0;
  }
`;

export const DesktopNavBar = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 10px;
`;

export const MobileNavSideBar = styled.div`
  position: fixed;
  width: 300px;
  height: 100vh;
  top: 0;
  opacity: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: ${(props) => props.theme.navbar.primary0};
  //background-color: #6de7b2;
  right: -100%;
  transition: ${(props) => props.theme.transitions.default.transition};
  &.open {
    opacity: 1;
    transition: ${(props) => props.theme.transitions.default.transition};
    right: 0%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const MobileNavLogo = styled.div`
  background-color: ${(props) => props.theme.colors.white.base};
  width: 100%;
  padding: 16px 10px 16px 10px;
`;

export const MobileCloseButton = styled(closeIcon)`
  //position: relative;
  width: 16px;
  height: 18px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #324054;
  margin: 10px 12px 10px auto;
  text-align: right;
`;

export const MobileMenuButton = styled(Link)`
  background-color: ${(p) => (p.active ? 'rgba(255, 255, 255, 0.3)' : 'transparent')};
  color: ${(props) => props.theme.colors.black.base};
  width: 80%;
  padding: 5px 15px 5px 15px;
  //border: 1px solid #ffffff;
  //opacity: 0.3;
  border-radius: 4px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
`;

export const MobileMenuText = styled(Link)`
  color: ${(props) => props.theme.colors.black.base};
  //font-weight: 500;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileMenuChev = styled.span`
  cursor: pointer;
  width: 8px;
  height: 8px;
  position: relative;
  top: -2px;
  margin-left: 5px;
  display: inline-flex;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.black.base};
  border-width: 2px 2px 0 0;
  transform: ${(p) => (p.show ? 'rotate(135deg)' : 'rotate(45deg)')};
  transition: transform 250ms ease;
`;

export const MobileCTAButton = styled(Link)`
  background-color: ${(props) => props.theme.colors.white.base};
  color: ${(props) => props.theme.colors.black.base};
  width: 80%;
  padding: 6px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  text-align: center;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  font-size: 17px;
`;

export const MobileMenuIcon = styled.div``;

export const HomeIcon = styled(HomeIconSVG)`
  margin-right: 15px;
`;

export const ServiceIcon = styled(ServiceIconSVG)`
  margin-right: 15px;
`;

export const IndustryIcon = styled(IndustryIconSVG)`
  margin-right: 15px;
`;

export const TechnologyIcon = styled(TechnologyIconSVG)`
  margin-right: 15px;
`;

export const ProcessIcon = styled(ProcessIconSVG)`
  margin-right: 15px;
`;
